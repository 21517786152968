var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-raw-material"},[_c('div',{staticClass:"content-tables-raw-material content-global"},[_c('div',{ref:"searchbarGlobal"},[_c('header-component-global',{attrs:{"sTextTitle":"Materias primas","sTextBtnAdd":"Añadir materia prima +","bAdminPermissions":_vm.bAdminMaterial,"setDialog":"setDialogAddRawMaterial"},on:{"setDialogAdd":_vm.setDialogAddRawMaterial}})],1),_c('div',{ref:"filterGlobal"},[_c('filter-component-global',{staticClass:"global-filters",attrs:{"labelName":"Buscador de materia prima","placeholderName":"Ingrese clave o nombre..."},on:{"setSearch":_vm.setSearch,"setExistent":_vm.setExistent,"setVariant":_vm.setVariant}})],1),_c('div',{staticClass:"global-content-table"},[(_vm.bEmptyTable)?_c('no-data-text-component-global',{attrs:{"sTextEmpty":_vm.sTextEmpty,"iHeight":_vm.screenHeight,"bAdminPermissions":_vm.bAdminMaterial,"setDialog":"setDialogAddRawMaterial"},on:{"setDialogAdd":_vm.setDialogAddRawMaterial}}):_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobile),expression:"!isMobile"}],staticClass:"global-table",attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"disable-sort":true,"loading":_vm.bLoadingTable,"loading-text":"Cargando datos...","items":_vm.aTable,"items-per-page":_vm.iItemsPerPage,"hide-default-footer":true,"no-data-text":"No hay información disponible para mostrar.","footer-props":{
          'items-per-page-text': 'Filas por página',
          'items-per-page-all-text': 'Todos',
        }},scopedSlots:_vm._u([{key:"item.sColorKey",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"content-color-id-raw-material"},[_c('div',{staticClass:"color-raw-material",style:({
                'background-color': '#' + item.sColorKey + '!important',
              })})])]}},{key:"item.dVariation",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"content-chip-difference"},[_c('v-chip',{staticClass:"chip-difference",attrs:{"color":_vm.getColor(item.dVariation),"dark":""}},[_c('div',{staticClass:"content-text-icon-variation"},[_c('div',{staticClass:"content-icon-variation"},[_c('v-icon',[_vm._v(_vm._s(_vm.getIcon(item.dVariation)))])],1),_c('div',{staticClass:"text-variation"},[_vm._v(_vm._s(item.dVariation)+" %")])])])],1)]}},{key:"item.accions",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{staticClass:"global-btn-table-detail",attrs:{"icon":""},on:{"click":function($event){return _vm.detailRawMaterial(item)}}},[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.bAdminMaterial),expression:"bAdminMaterial"}],staticClass:"global-btn-table-delete",attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v(" mdi-trash-can-outline ")])],1)]}},{key:"footer",fn:function(){return [_c('footer-table-component-global',{attrs:{"numPages":_vm.numPages,"totalItems":_vm.totalItems},on:{"changeTable":_vm.getEmitRawMaterial}})]},proxy:true}],null,true)},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"var(--primary-color-loading-table)","indeterminate":""}})],1)],2),_c('table-mobile-raw-material-component',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}],attrs:{"aTable":_vm.aTable,"headers":_vm.headers,"numPages":_vm.numPages,"totalItems":_vm.totalItems},on:{"changeTable":_vm.getEmitRawMaterial,"openDetailItem":_vm.detailRawMaterial,"openDeleteItem":_vm.deleteItem}})],1)]),_c('dialog-add-raw-material-component',{attrs:{"bDialogAddRawMaterial":_vm.bDialogAddRawMaterial},on:{"setDialogAddRawMaterial":_vm.setDialogAddRawMaterial}}),_c('delete-component-global',{attrs:{"oDialogDelete":_vm.oDialogDelete,"bDialogDelete":_vm.bDialogDelete},on:{"setDialogDelete":_vm.deleteItem}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }