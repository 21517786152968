<template>
  <div class="content-raw-material">
    <div class="content-tables-raw-material content-global">
      <div ref="searchbarGlobal">
        <header-component-global
          sTextTitle="Materias primas"
          sTextBtnAdd="Añadir materia prima +"
          :bAdminPermissions="bAdminMaterial"
          setDialog="setDialogAddRawMaterial"
          @setDialogAdd="setDialogAddRawMaterial"
        />
      </div>
      <div ref="filterGlobal">
        <filter-component-global
          class="global-filters"
          labelName="Buscador de materia prima"
          placeholderName="Ingrese clave o nombre..."
          @setSearch="setSearch"
          @setExistent="setExistent"
          @setVariant="setVariant"
        />
      </div>
      <div class="global-content-table">
        <no-data-text-component-global
          :sTextEmpty="sTextEmpty"
          :iHeight="screenHeight"
          v-if="bEmptyTable"
          :bAdminPermissions="bAdminMaterial"
          setDialog="setDialogAddRawMaterial"
          @setDialogAdd="setDialogAddRawMaterial"
        />
        <v-data-table
          v-else
          v-show="!isMobile"
          mobile-breakpoint="0"
          :headers="headers"
          :disable-sort="true"
          :loading="bLoadingTable"
          loading-text="Cargando datos..."
          :items="aTable"
          :items-per-page="iItemsPerPage"
          :hide-default-footer="true"
          no-data-text="No hay información disponible para mostrar."
          :footer-props="{
            'items-per-page-text': 'Filas por página',
            'items-per-page-all-text': 'Todos',
          }"
          class="global-table"
        >
          <template slot="progress">
            <v-progress-linear
              color="var(--primary-color-loading-table)"
              indeterminate
            ></v-progress-linear>
          </template>

          <template v-slot:[`item.sColorKey`]="{ item }">
            <div class="content-color-id-raw-material">
              <div
                :style="{
                  'background-color': '#' + item.sColorKey + '!important',
                }"
                class="color-raw-material"
              ></div>
            </div>
          </template>
          <!-- <template v-slot:[`item.dBasePurchasePrice`]="{ item }">
            <span>$ {{ formatMoneyGlobal(item.dBasePurchasePrice) }} MXN</span>
          </template>
          <template v-slot:[`item.dBaseSalePrice`]="{ item }">
            <span>$ {{ formatMoneyGlobal(item.dBaseSalePrice) }} MXN</span>
          </template> -->

          <template v-slot:[`item.dVariation`]="{ item }">
            <div class="content-chip-difference">
              <v-chip
                class="chip-difference"
                :color="getColor(item.dVariation)"
                dark
              >
                <div class="content-text-icon-variation">
                  <div class="content-icon-variation">
                    <v-icon>{{ getIcon(item.dVariation) }}</v-icon>
                  </div>
                  <div class="text-variation">{{ item.dVariation }} %</div>
                </div>
              </v-chip>
            </div>
          </template>

          <template v-slot:[`item.accions`]="{ item }">
            <v-btn
              @click="detailRawMaterial(item)"
              class="global-btn-table-detail"
              icon
            >
              <v-icon> mdi-eye-outline </v-icon>
            </v-btn>
            <v-btn
              v-show="bAdminMaterial"
              @click="deleteItem(item)"
              class="global-btn-table-delete"
              icon
            >
              <v-icon> mdi-trash-can-outline </v-icon>
            </v-btn>
          </template>

          <template v-slot:footer>
            <footer-table-component-global
              :numPages="numPages"
              :totalItems="totalItems"
              @changeTable="getEmitRawMaterial"
            />
          </template>
        </v-data-table>
        <table-mobile-raw-material-component
          v-show="isMobile"
          @changeTable="getEmitRawMaterial"
          @openDetailItem="detailRawMaterial"
          @openDeleteItem="deleteItem"
          :aTable="aTable"
          :headers="headers"
          :numPages="numPages"
          :totalItems="totalItems"
        />
      </div>
    </div>
    <!-- <footer-component-global /> -->
    <dialog-add-raw-material-component
      :bDialogAddRawMaterial="bDialogAddRawMaterial"
      @setDialogAddRawMaterial="setDialogAddRawMaterial"
    />
    <delete-component-global
      @setDialogDelete="deleteItem"
      :oDialogDelete="oDialogDelete"
      :bDialogDelete="bDialogDelete"
    />
  </div>
</template>

<script>
export default {
  name: "RawMaterial",
  data() {
    return {
      screenHeight: 0,
      heightMax: 0,
      screenWidth: 0,
      bLoadingTable: true,
      bEmptyTable: false,
      iCurrentPage: 1,
      iPageNumber: 1,
      iItemsPerPage: 30,
      numPages: 0,
      totalItems: 0,
      totalView: 0,
      sTextEmpty: "",

      headers: [
        {
          text: "",
          align: "center",
          sortable: false,
          value: "sColorKey",
          class: "global-header-table thColor",
          cellClass: "global-body-table ",
        },
        {
          text: "Clave",
          sortable: false,
          value: "sCode",
          class: "global-header-table",
          cellClass: "global-body-table",
        },
        {
          text: "Nombre",
          sortable: false,
          value: "sName",
          class: "global-header-table",
          cellClass: "global-body-table",
        },
        {
          text: "Existencia",
          value: "dInventory",
          align: "center",
          class: "global-header-table",
          cellClass: "global-body-table",
        },
        {
          text: "Precio base: Compra (MXN/USD)",
          value: "dBasePurchasePrice",
          align: "end",
          class: "global-header-table",
          cellClass: "global-body-table",
        },
        {
          text: "Precio base: Venta (MXN/USD)",
          value: "dBaseSalePrice",
          align: "end",
          class: "global-header-table",
          cellClass: "global-body-table",
        },
        {
          text: "Variación en pesos(MXN)",
          value: "dVariation",
          align: "center",
          class: "global-header-table",
          cellClass: "global-body-table",
        },
        {
          text: "Acciones",
          value: "accions",
          align: "center",
          class:
            "global-header-table  global-header-accions global-col-header-accion",
          cellClass:
            "global-body-table global-body-accions global-col-body-accion",
        },
      ],
      aTable: [],
      sSearch: "",
      textExistente: "",
      textVariante: "",
      isMobile: false,
      bDialogAddRawMaterial: false,
      bDialogDelete: false,
      oDialogDelete: {
        active: false,
        textTitle: "",
        textDescription: "",
        textQuestion: "",
        api: "",
        returnToView: false,
      },
    };
  },
  beforeMount() {
    this.$store.commit("setSort", null);
    this.$store.commit("setInOut", null);
    this.$store.commit("setTabPosition", { sTabPosition: 0 });
    this.$store.commit("setTabPositionOrderPayable", 0);
    this.setTextEmpty();
    this.getRawMaterial();
  },
  updated() {
    this.matchHeight();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    window.addEventListener("resize ", this.matchHeight);
    this.matchHeight();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    window.addEventListener("resize ", this.matchHeight);
  },
  methods: {
    setSearch(sSearch) {
      this.sSearch = sSearch;
    },
    setExistent(val) {
      this.textExistente = val;
    },
    setVariant(val) {
      this.textVariante = val;
    },
    setDialogAddRawMaterial() {
      this.bDialogAddRawMaterial = !this.bDialogAddRawMaterial;
    },
    //#region RESIZE PAGE
    matchHeight() {
      this.$nextTick((e) => {
        if (this.$refs.searchbarGlobal !== undefined) {
          this.heightMax =
            this.$refs.searchbarGlobal.clientHeight +
            this.$refs.filterGlobal.clientHeight;
          this.heightMax = this.heightMax + 90; //123 IS NAVBAR
        }
        if (window.innerWidth > 599) {
          this.isMobile = false;
          // this.screenHeight = window.innerHeight - this.heightMax;
          this.screenHeight = window.innerHeight - this.heightMax;
        } else {
          this.isMobile = true;
          // this.screenHeight = window.innerHeight - this.heightMax;
          this.screenHeight = window.innerHeight - this.heightMax;
        }
      });
    },
    handleResize: function () {
      this.matchHeight();
    },
    //#endregion RESIZE PAGE

    getColor(difference) {
      if (difference > 0) return "#00A85B";
      else if (difference < 0) return "#E85353";
      else return "#FFD54F";
    },
    getIcon(difference) {
      if (difference > 0) return "mdi-arrow-up";
      else if (difference < 0) return "mdi-arrow-down";
      else return "mdi-arrow-left-right";
    },
    detailRawMaterial(item) {
      this.$router
        .push({
          name: "RawMaterialDetail",
          params: { id: item.sRawMaterialId },
        })
        .catch(() => {});
    },
    deleteItem(item) {
      if (item) {
        this.oDialogDelete.textTitle = "Eliminar materia prima";
        this.oDialogDelete.textDescription =
          "La eliminación de una materia prima es una acción irreversible.";
        this.oDialogDelete.textQuestion =
          "Acepto la responsabilidad y deseo proseguir con la eliminación de la materia prima: " +
          item.sName +
          ".";
        this.oDialogDelete.api = `${URI}/api/sp/v1/raw-materials/${item.sRawMaterialId}`;
      }
      this.bDialogDelete = !this.bDialogDelete;
    },
    getEmitRawMaterial(obj) {
      this.iCurrentPage = obj.iCurrentPage;
      this.iItemsPerPage = obj.iItemsPerPage;
      this.getRawMaterial();
    },
    getRawMaterial() {
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          DB.get(`${URI}/api/sp/v1/raw-materials`, {
            params: {
              iPageNumber: this.iCurrentPage,
              iItemsPerPage: this.iItemsPerPage,
              sSearch: this.sSearch,
              sSort: this.textExistente,
              sInOut: this.textVariante,
            },
            headers: {
              Authorization: "Bearer " + this.$store.state.sToken,
            },
          })
            .then((response) => {
              this.numPages = response.data.numPages;
              this.totalItems = response.data.total;
              this.aTable = response.data.results.map((e) => {
                return {
                  ...e,
                  dBasePurchasePrice:
                    "$" +
                    this.formatMoneyGlobal(e.oPrices.oPurchase.dMexicanPeso) +
                    " MXN " +
                    " / " +
                    "$" +
                    this.formatMoneyGlobal(e.oPrices.oPurchase.dUSDollar) +
                    " USD",
                  dBaseSalePrice:
                    "$" +
                    this.formatMoneyGlobal(e.oPrices.oSales.dMexicanPeso) +
                    " MXN " +
                    " / " +
                    "$" +
                    this.formatMoneyGlobal(e.oPrices.oSales.dUSDollar) +
                    " USD",
                  dInventory:
                    FORMAT_MONEY(e.dInventory) +
                    " " +
                    e.oMeasurementUnit.sMeasurementUnitCode,
                };
              });
              this.bLoadingTable = false;
              this.bEmptyTable = this.aTable.length < 1;

              this.totalView = this.aTable.length;
              this.$store.commit("refresher", false);
            })
            .catch((error) => {
              this.bLoadingTable = false;
              this.Error(error.response.data);
            });
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    setTextEmpty() {
      let sTextemptyTemp = " <p>No existen materias primas registradas.</p>";
      this.bAdminMaterial
        ? (sTextemptyTemp +=
            "<p>Comienza <span class='text-information-link'>registrando una.</span> </p>")
        : "";
      this.sTextEmpty = sTextemptyTemp;
    },

    // FUNCTION OF FOOTER TABLE
    changeTable(e) {
      this.iItemsPerPage = e;
      this.iCurrentPage = 1;
      this.getRawMaterial();
    },
    backPage() {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    nextPage() {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },
    firstPage() {
      this.iCurrentPage = 1;
    },
    lastPage() {
      this.iCurrentPage = this.numPages;
    },
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
    sSort() {
      return this.$store.state.sSort;
    },
    sInOut() {
      return this.$store.state.sInOut;
    },
    bShowMaterial() {
      return this.$store.state.bShowMaterial;
    },
    bAdminMaterial() {
      return this.$store.state.bAdminMaterial;
    },
  },
  watch: {
    textExistente: function () {
      this.getRawMaterial();
    },
    textVariante: function () {
      this.getRawMaterial();
    },
    refreshTable: function () {
      this.getRawMaterial();
    },
    sSearch() {
      this.iCurrentPage = 1;
      this.getRawMaterial();
    },
    sInOut() {
      this.iCurrentPage = 1;
      this.getRawMaterial();
    },
    sSort() {
      this.iCurrentPage = 1;
      this.getRawMaterial();
    },
    iCurrentPage: function () {
      this.getRawMaterial();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content-raw-material {
  /* background-color: coral !important; */
  padding: 0px 30px 0px 35px;
}

.content-color-id-raw-material {
  display: flex;
  justify-content: center;
}

.color-raw-material {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  border: 1px solid var(--primary-color-text);
}

.content-chip-difference {
  display: flex;
  justify-content: center;
  width: 100%;
}

.chip-difference {
  align-content: center;
  width: 120px;
  text-align: center !important;
  justify-content: center;
  display: flex;
  font-family: "pop-Bold";
}

.content-text-icon-variation {
  display: flex;
  width: 120px;
}

.content-icon-variation {
  width: auto !important;
}

.text-variation {
  width: 100% !important;
  text-align: end !important;
  align-self: center !important;
}
</style>
